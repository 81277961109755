@font-face {
  font-family: $font-sans-serif;
  src: url("#{$font-path}/plex-sans/woff2/IBMPlexSans-Regular-Latin1.woff2")
      format("woff2"),
    url("#{$font-path}/plex-sans/woff/IBMPlexSans-Regular-Latin1.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $font-sans-serif;
  src: url("#{$font-path}/plex-sans/woff2/IBMPlexSans-Medium-Latin1.woff2")
      format("woff2"),
    url("#{$font-path}/plex-sans/woff/IBMPlexSans-Medium-Latin1.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $font-sans-serif;
  src: url("#{$font-path}/plex-sans/woff2/IBMPlexSans-BoldItalic-Latin1.woff2")
      format("woff2"),
    url("#{$font-path}/plex-sans/woff/IBMPlexSans-BoldItalic-Latin1.woff")
      format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: $font-mono;
  src: url("#{$font-path}/plex-mono/woff2/IBMPlexMono-Regular-Latin1.woff2")
      format("woff2"),
    url("#{$font-path}/plex-mono/woff/IBMPlexMono-Regular-Latin1.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}
