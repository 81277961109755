@use "sass:meta";
@use "@angular/material" as mat;
@use "src/assets/styles/collect-themes";
@use "src/assets/styles/themes";
@import "./assets/styles/variables";
@import "./assets/styles/font-files";
@import "./assets/styles/resets";

// material-icons
@import "material-icons/iconfont/material-icons.css";

// partials
@import "./scss/auth-forms";
@import "./scss/data-filter-bar";
@import "./scss/nav-lists";

// Common styles for Angular Material. Included so that we only have to load a
// single CSS file for Angular Material in our app.
// Don't include this anywhere else!

@include mat.core();
@include mat.all-component-typographies(themes.$custom-typography);
@include mat.card-typography(themes.$custom-card-typography);
@include mat.table-typography(themes.$custom-table-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes(themes.$candy-app-theme);
@include themes.theme-extension(themes.$candy-app-theme);

.light {
  @include meta.load-css("prismjs/themes/prism.css");
  @include collect-themes.generate-light-rules(themes.$candy-app-theme);
}

.dark {
  @include mat.all-component-colors(themes.$candy-app-theme-dark);
  @include themes.theme-extension(themes.$candy-app-theme-dark);
  @include meta.load-css("prismjs/themes/prism-twilight.css");
  @include collect-themes.generate-dark-rules(themes.$candy-app-theme-dark);
}

.light,
.dark {
  @include meta.load-css("prismjs/plugins/line-numbers/prism-line-numbers.css");
  @include meta.load-css("prismjs/plugins/line-highlight/prism-line-highlight.css");
}

// Responsive type maybe?
@media screen and (max-width: 767px) {
  @include mat.all-component-typographies(themes.$mobile-typography);
  @include mat.card-typography(themes.$custom-card-typography);
  @include mat.table-typography(themes.$custom-mobile-table-typography);
}

mat-card-content {
  font-family: $font-sans-serif;
}

mat-card-title {
  padding-bottom: 8px;
}

.vertical-card-header {
  flex-direction: column;
}

a {
  font-weight: 500;
}

ul {
  list-style: none;
  padding: unset;
}

svg {
  fill: currentColor;
}

.project-card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax((290px), 1fr));
  grid-gap: 24px;
}

header.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  >* {
    margin: 0;
  }
}

.l-body {
  max-width: 1100px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;

  &--full-width {
    max-width: 100%;
  }

  @media screen and (max-width: $small - 1) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.u-hidden {
  display: none;
}

.mat-headline-3,
.mat-headline-4 {
  margin-bottom: 1.2em;
}

.marginless {
  margin: 0;
}

.full-width {
  width: 100%;
}

.flex-common {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.tt-wrapping {
  overflow-wrap: break-word;
  height: auto;
}

.ngx-charts-tooltip-content.type-tooltip {
  font-family: $font-sans-serif !important;
}

.line-highlight {
  max-width: 100%;
}

.mat-mdc-header-cell {
  font-size: 12px;
}

// Taken from https://stackoverflow.com/a/57759753
.small-icon-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0px !important;
  display: inline-flex !important;
  margin-right: 8px;
  align-items: center;
  justify-content: center;

  &>*[role="img"] {
    width: 16px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.medium-icon-button {
  width: 36px !important;
  height: 36px !important;
  padding: 0px !important;

  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  &>*[role="img"] {
    width: 24px;
    height: 24px;
    font-size: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.comment-markdown {
  ul {
    list-style: disc outside;
    padding-left: 20px;
  }

  pre {
    margin-bottom: 20px;
  }

  blockquote {
    margin: 0 0 20px 0;
    padding: 20px 20px 20px 20px;

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    blockquote:last-child {
      margin-bottom: 0;
    }
  }
}

.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0f0;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #0f0;
  --mat-outlined-button-ripple-color: rgba(235, 106, 126, 0.1);
}

.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0f0;
  --mat-text-button-state-layer-color: #0f0;
  --mat-text-button-ripple-color: rgba(235, 106, 126, 0.1);
}

.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0f0;
  --mdc-filled-button-label-text-color: black;
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.dark .active-route .mdc-list-item__primary-text {
  color: #0f0;
}

.dark a {
  color: #0f0;
}

.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0f0;
  --mat-icon-button-state-layer-color: #0f0;
}

.dark gt-platform-picker li button.active {
  border: 1px solid #0f0;
}

.mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #0f0;
}

.dark .mat-mdc-tab-group,
.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0f0 !important;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fafafa;
  --mat-tab-header-inactive-label-text-color: rgba(250, 250, 250, 0.6);
  --mat-tab-header-active-label-text-color: #0f0 !important;
  --mat-tab-header-active-ripple-color: #0f0 !important;
  --mat-tab-header-inactive-ripple-color: #0f0 !important;
  --mat-tab-header-inactive-focus-label-text-color: rgba(250, 250, 250, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(250, 250, 250, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0f0 !important;
  --mat-tab-header-active-hover-label-text-color: #0f0 !important;
  --mat-tab-header-active-focus-indicator-color: #0f0 !important;
  --mat-tab-header-active-hover-indicator-color: #0f0 !important;
}

.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #0f0 !important;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #181818;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #181818;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0f0 !important;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before,
.mat-mdc-tab.mdc-tab--active .mat-ripple-element {
  background-color: #0f0 !important;
}

.mat-form-field-outline {
  border-color: #0f0 !important;
}

.mat-focused .mat-form-field-outline {
  border-color: #0f0 !important;
}

.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #0f0 !important;
}

.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fafafa;
  --mdc-radio-disabled-unselected-icon-color: #fafafa;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(250, 250, 250, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(250, 250, 250, 0.54);
  --mdc-radio-selected-focus-icon-color: #0f0 !important;
  --mdc-radio-selected-hover-icon-color: #0f0 !important;
  --mdc-radio-selected-icon-color: #0f0 !important;
  --mdc-radio-selected-pressed-icon-color: #0f0 !important;
  --mat-radio-ripple-color: #fafafa;
  --mat-radio-checked-ripple-color: #0f0 !important;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: #fafafa;
}

.dark .mat-accent {
  --mdc-slider-handle-color: #0f0 !important;
  --mdc-slider-focus-handle-color: #0f0 !important;
  --mdc-slider-hover-handle-color: #0f0 !important;
  --mdc-slider-active-track-color: #0f0 !important;
  --mdc-slider-inactive-track-color: #0f0 !important;
  --mdc-slider-with-tick-marks-inactive-container-color: #0f0 !important;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
  --mat-slider-ripple-color: #0f0 !important;
  --mat-slider-hover-state-layer-color: rgba(255, 110, 64, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 110, 64, 0.2);
}

.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #0f0 !important;
  --mat-toolbar-container-text-color: black !important;
}